import { GetKioskConfigurationResponseModel, UpdateConfigurationRequestModel } from 'models/backend/ConfigurationModel';
import { getApiKeyForHotel, getLoginUser, privateHttpClient } from '../utils/httpClient';

export const getAppConfiguration = async (hotelId: number) => {
  const { data } = await privateHttpClient.get('/api/configuration/webappconfig', {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel()
    },
    params: {
      hotelId: hotelId
    }
  });
  return data;
};

export const getConfigurationList = async (hotelConfigurationGroupId: number, index: number, size: number) => {
  const { data } = await privateHttpClient.get('/api/configuration', {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel()
    },
    params: {
      filter: `configurationGroupId eq ${hotelConfigurationGroupId}`,
      pageSize: size,
      pageIndex: index
    }
  });
  return data;
};

export const updateConfiguration = async (request: UpdateConfigurationRequestModel) => {
  const { data } = await privateHttpClient.put(
    '/api/Configuration',
    { ...request },
    {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel()
      }
    }
  );

  return data;
};

export const getKioskConfiguration = async (kioskCode: string) : Promise<GetKioskConfigurationResponseModel> => {
  const { data } = await privateHttpClient.get('/api/configuration/kiosk', {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel()
    },
    params: {
      code: kioskCode
    }
  });
  return data;
};