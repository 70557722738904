import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "reducers";

//Component
import {
  DataGrid,
  GridColDef,
  GridColumns,
  GridToolbarContainer,
} from "@material-ui/data-grid";
import CustomLoadingOverlay from "components/DataGridLoadingOverlay";
import CustomNoRowsOverlay from "components/DataGridNoRowsOverlay";
import {
  ARIA_SuccessCode,
  Component,
  Kiosk,
  PM_ADD_KIOSK,
  PM_DELETE_KIOSK,
  PM_EDIT_KIOSK,
  UpdateKioskRequestModel,
} from "models";
import { DataGridFilterToolbar } from "components/DataGridFilterToolbar";
import { GridColFilterDef } from "components/DataGridFilterSelection";
//Icon

//Helper
import { convertDateTimeToString } from "utils/dateHelper";
import { Data } from "react-csv/components/CommonPropTypes";
import { ExportButton } from "components/ExportButton";
import { MainDrawer } from "components/MainDrawer";

import {
  // Box,
  Button,
  // Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  TextField,
} from "@material-ui/core";
import {
  AccessControlButton,
  CodeMirrorTextField,
  ColoredCircle,
  ComponentSelector,
  KioskComponentDialog,
} from "components";
import {
  getKioskListAPI,
  createKioskAPI,
  updateKioskAPI,
  deleteKioskAPI,
} from "services/kioskService";
import {
  getKioskConfiguration
} from "services/configurationService"

import {
  GRID_FILTER_TYPE_BOOLEAN,
  GRID_FILTER_TYPE_KIOSK_LOCATION,
} from "components/DataGridFilterSelection/type";
import { fetchComponentList } from "reducers/component";
import { fetchKioskLocationList } from "reducers/kioskLocation";

export const KioskManagementScreen = () => {
  const dispatch = useDispatch();
  const { selectedHotel } = useSelector((state: RootState) => state.hotelState);
  // const { componentList } = useSelector((state: RootState) => state.component);
  const { kioskLocationList } = useSelector(
    (state: RootState) => state.kioskLocationState
  );

  const [index, setIndex] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const [row, setRow] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  const [requestDateTime, setRequestDateTime] = useState<Date>(new Date());

  const [kioskList, setKioskList] = useState<Kiosk[]>([]);
  const [exportList, setExportList] = useState<Data>([]);
  const [filterList, setFilterList] = useState<GridColFilterDef[]>([]);

  const [loadList, setloadList] = useState(true);

  const [addKiosk, setAddKiosk] = useState<any | undefined>();
  const [editingKiosk, setEditingKiosk] = useState<
    UpdateKioskRequestModel | undefined
  >();
  const [removeKiosk, setRemoveKiosk] = useState<Kiosk | undefined>();
  const [viewingKiosk, setViewingKiosk] = useState<Kiosk | undefined>();
  const [deleteConfirmation, setDeleteConfirmation] = useState("");

  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openDevicesDialog, setOpenDevivesDialog] = useState(false);

  //Setting up the each column header item
  const kioskNameHeader: GridColDef = {
    field: "name",
    headerName: "Name",
    renderHeader: (params: any) => <strong>{"Kiosk Name"}</strong>,
    minWidth: 240,
  };

  // const kioskCodeHeader: GridColDef = {
  //   field: "code",
  //   headerName: "Code",
  //   renderHeader: (params: any) => <strong>{"Code"}</strong>,
  //   minWidth: 240,
  // };

  const kioskLocationHeader: GridColDef = {
    field: "location",
    headerName: "Location",
    type: GRID_FILTER_TYPE_KIOSK_LOCATION,
    description: "location/code",
    renderHeader: (params: any) => <strong>{"Location"}</strong>,
    renderCell: (params: any) => {
      return params.value != null || params.value != undefined
        ? params.value.name
        : "-";
    },
    flex: 1,
  };

  const kioskIsOnlineHeader: GridColDef = {
    field: "isOnline",
    headerName: "Status",
    sortable: false,
    type: GRID_FILTER_TYPE_BOOLEAN,
    renderHeader: () => <strong>{"Status"}</strong>,
    renderCell: (params: any) => {
      if (params.value) {
        return <ColoredCircle color="#297373" />;
      } else {
        return <ColoredCircle color="#E94F37" />;
      }
    },
    minWidth: 40,
  };

  const kioskIsOnMaintaineceHeader: GridColDef = {
    field: "isOnMaintenanceMode",
    headerName: "Maintenance Mode",
    sortable: false,
    type: GRID_FILTER_TYPE_BOOLEAN,
    renderHeader: () => <strong>{"Maintenance Mode"}</strong>,
    renderCell: (params: any) => {
      if (params.value) {
        return <ColoredCircle color="#297373" />;
      } else {
        return <ColoredCircle color="#E94F37" />;
      }
    },
    minWidth: 160,
  };

  const kioskComponentsHeader: GridColDef = {
    field: "components",
    headerName: "Components",
    align: "center",
    sortable: false,
    filterable: false,
    renderHeader: () => <strong>{"Components (O/M/T)"}</strong>,
    renderCell: (params: any) => {
      var components = params.value as Component[];
      var totalComponent = components.length;
      if (totalComponent > 0) {
        var onlineComponents = components.filter((x) => x.isOnline).length;
        var maintenanceComponents = components.filter(
          (x) => x.IsOnMaintenanceMode
        ).length;

        if (onlineComponents == totalComponent) {
          return <ColoredCircle color="#297373" />;
        } else {
          return `${onlineComponents} / ${maintenanceComponents} / ${totalComponent}`;
        }
      } else {
        return "NA";
      }
    },
    minWidth: 180,
  };

  const actionsHeader: GridColDef = {
    field: "id",
    headerName: "Actions",
    minWidth: 320,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    renderHeader: () => <strong>{"Actions"}</strong>,
    renderCell: (params: any) => (
      <strong>
        <AccessControlButton
          code={PM_EDIT_KIOSK}
          variant="contained"
          color="primary"
          size="small"
          value={params.value}
          onClick={handleOpenEditDialog}
        >
          Edit
        </AccessControlButton>
        <AccessControlButton
          code={PM_ADD_KIOSK}
          variant="contained"
          color="primary"
          size="small"
          value={params.value}
          style={{ marginLeft: 12 }}
          onClick={handleDuplicateFunctionClick}
        >
          Clone
        </AccessControlButton>
        <AccessControlButton
          code={PM_DELETE_KIOSK}
          variant="contained"
          color="primary"
          size="small"
          value={params.value}
          style={{ marginLeft: 12 }}
          onClick={handleOpenDeleteDialog}
        >
          Delete
        </AccessControlButton>
        <Button
          variant="contained"
          color="primary"
          size="small"
          value={params.value}
          style={{ marginLeft: 12, textTransform: "none" }}
          onClick={handleOpenDevicesDialog}
        >
          Devices
        </Button>
      </strong>
    ),
  };

  const headerColumn: GridColumns = [
    kioskNameHeader,
    // kioskCodeHeader,
    kioskLocationHeader,
    kioskComponentsHeader,
    kioskIsOnlineHeader,
    kioskIsOnMaintaineceHeader,
    actionsHeader,
  ];

  React.useEffect(() => {
    if (selectedHotel != undefined) {
      dispatch(fetchComponentList());
      dispatch(fetchKioskLocationList());
      setloadList(true);
    }
  }, [selectedHotel]);

  React.useEffect(() => {
    if (loadList) {
      setIsLoading(true);
      setloadList(false);
      setKioskList([]);
      setRequestDateTime(new Date());

      getKioskListAPI({
        filter: filterList,
        pageIndex: index,
        pageSize: size,
        orderBy: "name",
      })
        .then((response) => {
          if (response.resultCode === ARIA_SuccessCode) {
            setKioskList(response.result.items);
            setRow(response.result.rowCount);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [index, size, filterList, loadList]);

  const getExportTransaction = async () => {
    setIsLoading(true);
    var _exportIndex = 1;
    var exportList: Kiosk[] = [];

    try {
      do {
        var response = await getKioskListAPI({
          filter: filterList,
          pageIndex: _exportIndex,
          pageSize: size,
          orderBy: "name",
        });

        exportList.push(...response.result.items);

        _exportIndex++;
      } while (response.result.pageCount >= _exportIndex);

      setExportList(exportList);
      setIsExporting(true);
      setIsLoading(false);
    } catch (err) {
      console.log("Oposs.. Something is wrong");
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <DataGridFilterToolbar
          headerColumns={headerColumn}
          filterList={filterList}
          onFilterApply={(items) => {
            setFilterList(items.slice());
            setloadList(true);
          }}
        />
        <ExportButton
          filename="Kiosk"
          data={exportList}
          headers={[
            { label: "Name", key: "name" },
            { label: "Code", key: "code" },
            { label: "Description", key: "description" },
          ]}
          readyToExport={isExporting}
          onClick={getExportTransaction}
          onFinishDownload={() => {
            setExportList([]);
            setIsExporting(false);
          }}
        >
          Export
        </ExportButton>
        <div style={{ flexGrow: 1 }}></div>
        <AccessControlButton
          code={PM_ADD_KIOSK}
          variant="contained"
          color="primary"
          size="small"
          onClick={handleOpenCreateDialog}
        >
          New Kiosk
        </AccessControlButton>
        <div style={{ paddingLeft: "8px" }}>
          <b>Data as at </b>: {convertDateTimeToString(requestDateTime)}
        </div>
      </GridToolbarContainer>
    );
  }

  const handleOpenCreateDialog = () => {
    setOpenEditDialog(true);
  };

  const handleDuplicateFunctionClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    var _updateKiosk = kioskList.find(
      (f) => f.id === parseInt(event.currentTarget.value)
    );

    setAddKiosk(_updateKiosk);
    setOpenEditDialog(true);
  };

  const handleOpenEditDialog = (event: React.MouseEvent<HTMLButtonElement>) => {
    var _updateKiosk = kioskList.find(
      (f) => f.id === parseInt(event.currentTarget.value)
    );

    if (_updateKiosk != undefined) {
      var componentIds: number[] = [];
      var locationId = 0;
      var configValue = "";

      if (
        _updateKiosk.components != undefined ||
        _updateKiosk.components != null
      ) {
        componentIds = _updateKiosk?.components?.map((x) => x.id);
      }

      if (_updateKiosk.location != undefined || _updateKiosk.location != null) {
        locationId = _updateKiosk.location.id;
      }

      getKioskConfiguration(_updateKiosk.code)
        .then((response ) => {
          if (response.resultCode === ARIA_SuccessCode) {
            configValue = response.result.configValue
          }
        })
        .finally(() => {
          setEditingKiosk({
            id: _updateKiosk!!.id,
            code: _updateKiosk!!.code,
            name: _updateKiosk!!.name,
            description: _updateKiosk!!.description,
            configValue: configValue,
            locationId: locationId,
            componentIds: componentIds,
          });
          setOpenEditDialog(true);
        });
    }
  };

  const handleOpenDeleteDialog = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    var _removeKiosk = kioskList.find(
      (f) => f.id === parseInt(event.currentTarget.value)
    );
    setRemoveKiosk(_removeKiosk);
    setOpenDeleteDialog(true);
  };

  const handleOpenDevicesDialog = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    var _viewingKiosk = kioskList.find(
      (f) => f.id === parseInt(event.currentTarget.value)
    );
    setViewingKiosk(_viewingKiosk);
    setOpenDevivesDialog(true);
  };

  const handleEditOrAdd = () => {
    if (editingKiosk == null) {
      if (
        addKiosk === undefined ||
        addKiosk.name === undefined ||
        addKiosk.name === "" ||
        addKiosk.code === undefined ||
        addKiosk.code === ""
      ) {
        alert("Please fill up the details below to add a new Kiosk.");
      } else {
        try {
          createKioskAPI({
            name: addKiosk.name,
            code: addKiosk.code,
            description: addKiosk.description,
            componentIds: addKiosk.componentIds,
            locationId: addKiosk.locationId,
            configValue: addKiosk.configValue!= null ? JSON.stringify(JSON.parse(addKiosk.configValue)) : null,
          })
            .then((response) => {
              if (response.resultCode == ARIA_SuccessCode) {
                setloadList(true);
                handleEditClose();
              } else {
                alert(response.resultDescription);
              }
            })
            .catch((err) => {
              alert(err);
            });
        } catch (err) {
          alert("JSON Syntax Error!!");
        }
      }
    } else {
      if (
        editingKiosk.name === null ||
        editingKiosk.name === "" ||
        editingKiosk.code === null ||
        editingKiosk.code === ""
      ) {
        alert("Please fill up the details below to edit kiosk.");
      } else {
        try{
          updateKioskAPI({
            id: editingKiosk.id,
            code: editingKiosk.code,
            name: editingKiosk.name,
            description: editingKiosk.description,
            componentIds: editingKiosk.componentIds,
            locationId: editingKiosk.locationId,
            configValue: editingKiosk.configValue != null ? JSON.stringify(JSON.parse(editingKiosk.configValue)) : null,
          })
            .then((response) => {
              if (response.resultCode == ARIA_SuccessCode) {
                setloadList(true);
                handleEditClose();
              } else {
                alert(response.resultDescription);
              }
            })
            .catch((err) => {
              alert(err);
            });
        } catch (err) {
          alert("JSON Syntax Error!!");
        }
      }
    }
  };

  const handleDelete = () => {
    if (deleteConfirmation !== removeKiosk?.code) {
      alert("Please key in the kiosk code to confirm deletion.");
    } else {
      deleteKioskAPI({ ...removeKiosk })
        .catch((err) => {
          alert(err);
        })
        .finally(() => {
          setDeleteConfirmation("");
          setloadList(true);
          handleEditClose();
        });
    }
  };

  const handleEditClose = () => {
    setAddKiosk(undefined);
    setEditingKiosk(undefined);
    setViewingKiosk(undefined);
    setOpenEditDialog(false);
    setOpenDeleteDialog(false);
    setOpenDevivesDialog(false);
  };

  return (
    <>
      <MainDrawer title="Kiosk">
        {/* Add and Edit Dialog */}
        <Dialog
          open={openEditDialog}
          onClose={handleEditClose}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth="lg"
        >
          <DialogTitle id="form-dialog-title">
            {editingKiosk != undefined ? "Edit Kiosk" : "Add Kiosk"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {editingKiosk != undefined
                ? "Change the detail below and save it to update kiosk details."
                : "Fill up the details below to add a new kiosk."}
            </DialogContentText>
            <form noValidate autoComplete="off">
              <div>
                <TextField
                  margin="dense"
                  id="name"
                  label="Kiosk Name"
                  type="text"
                  value={
                    editingKiosk != undefined
                      ? editingKiosk.name
                      : addKiosk?.name != undefined
                      ? addKiosk?.name
                      : ""
                  }
                  onChange={(e) => {
                    editingKiosk != undefined
                      ? setEditingKiosk({
                          ...editingKiosk,
                          name: e.currentTarget.value,
                        })
                      : setAddKiosk({
                          ...addKiosk,
                          name: e.currentTarget.value,
                        });
                  }}
                  fullWidth
                />
                <TextField
                  margin="dense"
                  id="code"
                  label="Code"
                  type="text"
                  value={
                    editingKiosk != undefined
                      ? editingKiosk.code
                      : addKiosk?.code != undefined
                      ? addKiosk?.code
                      : ""
                  }
                  onChange={(e) => {
                    editingKiosk != undefined
                      ? setEditingKiosk({
                          ...editingKiosk,
                          code: e.currentTarget.value,
                        })
                      : setAddKiosk({
                          ...addKiosk,
                          code: e.currentTarget.value,
                        });
                  }}
                  fullWidth
                />
                <TextField
                  margin="dense"
                  id="desc"
                  label="Description"
                  type="text"
                  multiline
                  value={
                    editingKiosk != undefined
                      ? editingKiosk.description
                      : addKiosk?.description != undefined
                      ? addKiosk?.description
                      : ""
                  }
                  onChange={(e) => {
                    editingKiosk != undefined
                      ? setEditingKiosk({
                          ...editingKiosk,
                          description: e.currentTarget.value,
                        })
                      : setAddKiosk({
                          ...addKiosk,
                          description: e.currentTarget.value,
                        });
                  }}
                  fullWidth
                />
                <TextField
                  margin="dense"
                  id="location"
                  label="Location"
                  select
                  value={
                    editingKiosk != undefined
                      ? editingKiosk.locationId
                      : addKiosk?.locationId
                  }
                  onChange={(e) => {
                    editingKiosk != undefined
                      ? setEditingKiosk({
                          ...editingKiosk,
                          locationId: parseInt(e.target.value),
                        })
                      : setAddKiosk({
                          ...addKiosk,
                          locationId: parseInt(e.target.value),
                        });
                  }}
                  fullWidth
                >
                  {kioskLocationList.map((kioskLocation, index) => (
                    <MenuItem key={index} value={kioskLocation.id}>
                      {kioskLocation.name}
                    </MenuItem>
                  ))}
                </TextField>
                <ComponentSelector
                  value={
                    editingKiosk != undefined
                      ? editingKiosk.componentIds
                      : addKiosk?.componentIds
                  }
                  onChange={(componentIds) => {
                    editingKiosk != undefined
                      ? setEditingKiosk({
                          ...editingKiosk,
                          componentIds: componentIds,
                        })
                      : setAddKiosk({
                          ...addKiosk,
                          componentIds: componentIds,
                        });
                  }}
                />
                <CodeMirrorTextField
                  value={
                    editingKiosk != undefined
                      ? editingKiosk.configValue
                      : addKiosk?.configValue != undefined
                      ? addKiosk?.configValue
                      : ""
                  }
                  onChange={(value) => {
                    editingKiosk != undefined
                      ? setEditingKiosk({
                          ...editingKiosk,
                          configValue: value,
                        })
                      : setAddKiosk({
                          ...addKiosk,
                          configValue: value,
                        });
                  }}
                />
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleEditOrAdd} color="primary">
              {editingKiosk != null ? `Save & Update` : `Add Kiosk`}
            </Button>
          </DialogActions>
        </Dialog>

        {/* delete Dialog */}
        {removeKiosk != undefined ? (
          <Dialog
            open={openDeleteDialog}
            onClose={handleEditClose}
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle id="form-dialog-title">Delete Kiosk</DialogTitle>
            <DialogContent>
              <form noValidate autoComplete="off">
                <div>
                  <TextField
                    margin="dense"
                    id="name"
                    label="Kiosk Name"
                    type="text"
                    disabled
                    value={removeKiosk.name}
                    fullWidth
                  />
                  <TextField
                    margin="dense"
                    id="code"
                    label="Code"
                    type="text"
                    disabled
                    value={removeKiosk.code}
                    fullWidth
                  />
                  <TextField
                    margin="dense"
                    id="description"
                    label="Description"
                    type="text"
                    disabled
                    multiline
                    value={removeKiosk.description}
                    fullWidth
                  />
                  <DialogContentText
                    color="textPrimary"
                    style={{ marginTop: "18px" }}
                  >
                    {`Are you sure you want to delete ${removeKiosk.name}?`}
                  </DialogContentText>
                  <DialogContentText>
                    Please type the following to confirm:
                  </DialogContentText>
                  <DialogContentText>
                    <i>
                      <b>{removeKiosk.code}</b>
                    </i>
                  </DialogContentText>
                  <TextField
                    margin="dense"
                    variant="outlined"
                    id="code"
                    type="text"
                    value={deleteConfirmation}
                    onChange={(e) =>
                      setDeleteConfirmation(e.currentTarget.value)
                    }
                    fullWidth
                  />
                </div>
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleEditClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleDelete} color="primary">
                {`Delete`}
              </Button>
            </DialogActions>
          </Dialog>
        ) : null}

        {/* devices Dialog */}
        {viewingKiosk != undefined ? (
          <KioskComponentDialog
            open={openDevicesDialog}
            kiosk={viewingKiosk}
            onCancelClicked={handleEditClose}
          />
        ) : null}

        {/* Data Grid */}
        <div style={{ height: "80vh" }}>
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                pageSize={size}
                rowsPerPageOptions={[10, 20, 30]}
                onPageSizeChange={(size) => {
                  setSize(size);
                }}
                disableColumnMenu
                density="compact"
                components={{
                  LoadingOverlay: CustomLoadingOverlay,
                  NoRowsOverlay: CustomNoRowsOverlay,
                  Toolbar: CustomToolbar,
                }}
                pagination
                loading={isLoading}
                paginationMode="server"
                rowCount={row}
                hideFooterSelectedRowCount={true}
                filterMode="server"
                onPageChange={(newPage) => {
                  setIndex(++newPage);
                }}
                rows={kioskList}
                columns={headerColumn}
                getRowId={(row) => row.id}
              />
            </div>
          </div>
        </div>
      </MainDrawer>
    </>
  );
};
