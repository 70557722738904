import React, { useState } from "react";
// @material-ui/core components
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { DynamicTypography } from "components/DynamicTypography";
import { GridContainer } from "components/GridContainer";
import { GridItem } from "components/GridItem";
import axios from "axios";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { DocScanModel } from "./DocScanModel";

const iDocscanHttpClient = axios.create({
  baseURL: "https://www.at.operadocscan.com",
  headers: {
    "Content-type": "application/json",
  },
});

const startScanAPI = async (): Promise<DocScanModel> => {
  const { data } = await iDocscanHttpClient.get(`/api/scandoc/scan`, {
    headers: {
      "Content-type": "application/json",
    },
  });

  return data;
};

function objectToArrays<T>(obj: T): [Array<keyof T>, Array<T[keyof T]>] {
  const keys: Array<keyof T> = [];
  const values: Array<T[keyof T]> = [];

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      keys.push(key);
      values.push(obj[key]);
    }
  }

  return [keys, values];
}

interface IProps {
  open: boolean;
  onCancelClicked?: () => void
}

export const IDocScanDialog: React.FC<IProps> = ({ open, onCancelClicked }) => {
  //   const [scanDoc, setScanDoc] = useState<DocScanModel | undefined>(undefined);
  const [items, setItems] = useState<(keyof DocScanModel)[]>([]);
  const [values, setValues] = useState<
    (string | number | Date | null | undefined)[]
  >([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const onDownloadClicked = () => {
    setIsLoading(true);
    startScanAPI()
      .then((document) => {
        const [keys, values] = objectToArrays(document);

        setItems(keys);
        setValues(values);
      })
      .catch((err) => {
        var error: DocScanModel = {
          ResultCode: 500,
          ResultDescription: err.message,
        };

        const [keys, values] = objectToArrays(error);

        setItems(keys);
        setValues(values);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onCancel = () => {
    setItems([])
    setValues([])
    
    if(onCancelClicked){
        onCancelClicked()
    }
  }

  return (
    <Dialog open={open} fullWidth maxWidth="xl">
      <DialogTitle>iDocScan</DialogTitle>
      <DialogContent>
        <DynamicTypography
          content={"Click start to trigger document scanner for result"}
        />
        <GridContainer
          spacing={2}
          direction="row"
          alignItems="center"
          alignContent="center"
        >
          <GridItem xs={12}>
            <Button
              size="large"
              color="primary"
              variant="contained"
              disabled={isLoading}
              onClick={onDownloadClicked}
            >
              Trigger scanner for document
            </Button>
          </GridItem>
          {items.length > 0 ? (
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b>Key</b>
                    </TableCell>
                    <TableCell>
                      <b>Value</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items.map((keys, index) => {
                    if (keys.toLowerCase().includes("image")) {
                      return (
                        <TableRow key={keys}>
                          <TableCell component="th" scope="row">
                            {keys}
                          </TableCell>
                          <TableCell>
                            {keys.toLowerCase().includes("faceimage") ? (
                              values[index] ?
                              (<img
                                style={{ height: "20vh" }}
                                src={`data:image/JPEG;base64, ${(values[index] as string).replace('JPEG:', '')}`}
                              />) : ( <span></span>)
                            ) : (
                              values[index] ? (
                              <img
                                style={{ height: "50vh" }}
                                src={`data:image/JPEG;base64, ${(values[index] as string).replace('JPEG:', '')}`}
                              />) : ( <span></span>)
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    } else if(values[index] != null){
                      return (
                        <TableRow key={keys}>
                          <TableCell component="th" scope="row">
                            {keys}
                          </TableCell>
                          <TableCell>{values[index]}</TableCell>
                        </TableRow>
                      );
                    } else {
                        return null;
                    }
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : null}
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};
